<template>
  <div class="CollegeAdmin">
    <div class="box-card">
      <div slot="header" class="header">
        <span class="title">角色管理</span>
      </div>
      <div class="Main-box">
        <div class="User-add" @click="handleAdd">
          <div class="Item_box">
            <img src="../../../assets/college/College/add_icon.png" class="add_image" />
          </div>
          <span class="Item_text">添加角色</span>
        </div>
        <div v-for="(item, index) in roleList" :key="index" class="User-exit">
          <div class="User_image">
            <div class="item_box">
              <img src="../../../assets/college/College/user_icon.png" class="user_image" />
            </div>
            <span class="item_text">{{ item.roleName }}</span>
          </div>
          <div class="User_operate">
            <span @click="handleEdit(item)">编辑</span>
            <span @click="handleJurisdiction(item)">授权</span>
            <span @click="handleDelete(item)">删除</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="status == 1 ? '添加角色' : '编辑角色'"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="角色名称：" prop="roleName">
          <el-input v-model="form.roleName" placeholder="请输入2-10字角色名称" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取消</el-button>
        <el-button type="primary" @click="handleSubmit('form')">确定</el-button>
      </div>
    </el-dialog>
    <Jurisdiction ref="Jurisdiction" @success="getAllRoleData()"></Jurisdiction>
  </div>
</template>
<script>
const listObj = {
  roleName: '',
}
import Jurisdiction from './Jurisdiction'
import { getAllRole, getAddRole, getEditRole, getBatchDelRole } from '@/api/college'
import to from 'await-to'
export default {
  name: 'CollegeAdmin',
  components: {
    Jurisdiction,
  },
  data() {
    return {
      roleList: [],
      dialogVisible: false,
      status: 0,
      form: {
        roleName: '',
        roleId: '',
      },
      rules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { min: 2, max: 10, message: '请输入2-10字角色名称' },
        ],
      },
      loading: false,
      clientCode: '260bb55c-47e3-48d0-bd8c-c2cf1ce5',
      pager: {
        size: 1000,
        current: 1,
      },
    }
  },
  created() {
    this.getAllRoleData()
  },
  methods: {
    async getAllRoleData() {
      const { size, current } = this.pager
      const [res, err] = await to(getAllRole({ clientCode: this.clientCode, size, current }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.roleList = res.data.list
    },
    //添加
    handleAdd() {
      this.status = 1
      this.dialogVisible = true
    },
    closeDialog() {
      if (this.status == 2) {
        this.form = Object.assign({}, listObj)
      }
    },
    cancel() {
      this.dialogVisible = false
      this.closeDialog()
    },
    // 编辑
    handleEdit(row) {
      this.status = 2
      this.dialogVisible = true
      this.form = Object.assign({}, row)
    },
    async handleSubmit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (this.status == 1) {
            const [, err] = await to(
              getAddRole({ clientCode: this.clientCode, roleName: this.form.roleName }),
            )
            if (err) return this.$message.warning(err.msg)
            this.$message.success('添加成功')
          } else {
            const [, err] = await to(getEditRole({ clientCode: this.clientCode, ...this.form }))
            if (err) return this.$message.warning(err.msg)
            this.$message.success('编辑成功')
          }
          this.$refs[formName].resetFields()
          this.dialogVisible = false
          this.getAllRoleData()
        }
      })
    },
    // 删除
    async handleDelete(row) {
      let arr = []
      arr.push(row.roleId)
      this.$confirm('你确认要删除吗?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const [, err] = await to(getBatchDelRole({ ids: arr }))
          if (err) return this.$message({ type: 'error', message: err.msg })
          this.$message({ type: 'success', message: '删除成功' })
          this.getAllRoleData()
        })
        .catch(() => {})
    },
    //授权
    handleJurisdiction(row) {
      this.$refs.Jurisdiction.show(row.roleId)
    },
  },
}
</script>
<style lang="scss" scoped>
.box-card {
  background: #ffffff;
  .header {
    height: 55px;
    border-bottom: 1px solid #e2e2e2;
    padding: 0 21px;
    .title {
      font-size: 18px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
      line-height: 55px;
    }
  }
  .Main-box {
    box-sizing: border-box;
    display: flex;
    flex-flow: wrap;
    padding: 0 44px 50px 44px;
    .User-add {
      margin-top: 38px;
      margin-right: 33px;
      width: 180px;
      height: 145px;
      background: #fafafa;
      border: 1px solid #c2c2c2;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      .Item_box {
        display: flex;
        justify-content: center;
        .add_image {
          margin-top: 51px;
          margin-bottom: 12px;
          width: 19px;
          height: 18px;
        }
      }
      .Item_text {
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        color: #909399;
      }
    }
    .User-exit {
      margin: 38px 33px 0px 0px;
      width: 180px;
      height: 145px;
      opacity: 0.8;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      .User_image {
        height: 98px;
        border-bottom: 1px solid #e2e2e2;
        text-align: center;
        .item_box {
          display: flex;
          justify-content: center;
          .user_image {
            margin-top: 12px;
            margin-bottom: 10px;
            width: 41px;
            height: 41px;
          }
        }
        .item_text {
          font-size: 16px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          font-weight: 400;
          color: #333333;
        }
      }
      .User_operate {
        display: flex;
        justify-content: space-between;
        padding: 12px 20px 0px 20px;
        span {
          font-size: 14px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          font-weight: 400;
          color: #ff7b33;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
