<template>
  <div>
    <!-- 授权对话框 -->
    <el-dialog
      title="权限设置"
      :visible.sync="jurisdictionDialogFormVisible"
      width="1100px"
      class="Item_set"
      @close="closeDialog"
    >
      <el-checkbox
        v-model="checkallSection"
        :indeterminate="isIndeterminate"
        label="全选"
        name="type"
        @change="handleCheckSection"
      />
      <div class="box-check">
        <div v-for="(item, index) in menuList" :key="index" ref="multiple" class="showmenu">
          <template>
            <div class="item_menu">
              <el-checkbox
                v-model="item.Ischeck"
                :indeterminate="item.isSecondIndeterminate"
                @change="handleCheckAllChange(item.Ischeck, index)"
                >{{ item.meta.title }}</el-checkbox
              >
            </div>
            <el-checkbox-group
              v-model="item.checkedData"
              class="iten-child-menu"
              @change="handleCheckedChange(item.checkedData, index)"
            >
              <el-checkbox v-for="(child, id) in item.children" :key="id" :label="child.menuId">{{
                child.menuName
              }}</el-checkbox>
            </el-checkbox-group>
          </template>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="jurisdictionBtn">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import to from 'await-to'
import { roleMenu, getRoleJurisdiction, getSureJurisdiction } from '@/api/college'
export default {
  data() {
    return {
      checkallSection: false,
      roleId: '',
      jurisdictionDialogFormVisible: false,
      isIndeterminate: false, //对el-checkbox控制不完整的全选状态
      clientCode: '260bb55c-47e3-48d0-bd8c-c2cf1ce5',
      menuList: [],
      idArr: [],
    }
  },
  mounted() {},
  methods: {
    show(roleId) {
      this.roleId = roleId
      this.jurisdictionDialogFormVisible = true
      this.roleMenuData()
    },
    async roleMenuData() {
      const [res, err] = await to(roleMenu({ clientCode: this.clientCode }))
      if (err) return this.$message.warning(err.msg)
      this.menuList = res.data
      this.menuList.forEach(item => {
        this.$set(item, 'isSecondIndeterminate', false)
        this.$set(item, 'Ischeck', false)
        this.$set(item, 'checkedData', new Array())
      })
      this.getRoleJurisdictionData(this.roleId)
    },
    async getRoleJurisdictionData(roleId) {
      const [res, err] = await to(
        getRoleJurisdiction({ clientCode: this.clientCode, roleId: roleId }),
      )
      if (err) return this.$message.warning(err.msg)
      this.idArr = res.data
      const arr = this.menuList
      let newList = []
      let checkList = []
      //遍历数组对比参数，并赋值三级
      for (let i = 0; i < arr.length; i++) {
        let ab = arr[i].checkedData
        for (let k = 0; k < arr[i].children.length; k++) {
          newList.push(arr[i].children[k])
          let bk = arr[i].children[k].menuId
          for (let j = 0; j < this.idArr.length; j++) {
            if (this.idArr[j] == bk) {
              ab.push(this.idArr[j])
            }
          }
        }
        //赋值二级
        if (ab.length > 0 && ab.length < arr[i].children.length) {
          this.menuList[i].isSecondIndeterminate = true
        } else if (ab.length > 0 && ab.length == arr[i].children.length) {
          this.menuList[i].Ischeck = true
        } else {
          this.menuList[i].Ischeck = false
        }
        //赋值一级
        for (let m = 0; m < ab.length; m++) {
          checkList.push(ab[m])
        }
      }
      if (checkList.length > 0 && checkList.length < newList.length) {
        this.isIndeterminate = true
      } else if (checkList.length > 0 && checkList.length == newList.length) {
        this.checkallSection = true
      } else {
        this.checkallSection = false
      }
    },
    //所有的选项全选事件
    handleCheckSection(value) {
      const arr = this.menuList
      if (value) {
        for (let i = 0; i < arr.length; i++) {
          this.isIndeterminate = false
          arr[i].isSecondIndeterminate = false
          arr[i].Ischeck = true
          let List = []
          for (let k = 0; k < arr[i].children.length; k++) {
            List[k] = arr[i].children[k].menuId
          }
          arr[i].checkedData = List
        }
      } else {
        for (let i = 0; i < arr.length; i++) {
          arr[i].Ischeck = false
          arr[i].checkedData = []
        }
      }
    },
    handleCheckAllChange(val, index) {
      let arr = []
      const re = this.menuList[index].children
      //全选
      if (val) {
        for (let i = 0; i < re.length; i++) {
          arr[i] = re[i].menuId
        }
      }
      this.menuList[index].checkedData = arr
      this.menuList[index].isSecondIndeterminate = false
      let arry = []
      for (let i = 0; i < this.menuList.length; i++) {
        if (this.menuList[i].Ischeck == true) {
          arry.push('true')
        } else {
          arry.push('false')
        }
      }
      let num = 0
      for (let k = 0; k < arry.length; k++) {
        if (arry[k] == 'true') {
          num = num + 1
        }
      }
      this.checkallSection = num === this.menuList.length
      this.isIndeterminate = num > 0 && num < this.menuList.length
    },
    handleCheckedChange(value, index) {
      let checkedCount = value.length
      this.menuList[index].Ischeck = checkedCount === this.menuList[index].children.length
      this.menuList[index].isSecondIndeterminate =
        checkedCount > 0 && checkedCount < this.menuList[index].children.length
      let arr = []
      let object = []
      for (let i = 0; i < this.menuList.length; i++) {
        if (this.menuList[i].checkedData.length == this.menuList[i].children.length) {
          arr.push('true')
        } else {
          arr.push('false')
        }
        if (this.menuList[i].checkedData.length > 0) {
          object.push('true')
        } else {
          object.push('false')
        }
      }
      let num = 0
      let ValueLength = 0
      for (let k = 0; k < arr.length; k++) {
        if (arr[k] == 'true') {
          num = num + 1
        }
      }
      for (let k = 0; k < object.length; k++) {
        if (object[k] == 'true') {
          ValueLength = ValueLength + 1
        }
      }
      this.checkallSection = num === this.menuList.length
      this.isIndeterminate = ValueLength > 0 && num < this.menuList.length
    },
    //关闭弹框的事件
    closeDialog() {
      this.checkallSection = false
      this.isIndeterminate = false
    },
    //提交事件
    async jurisdictionBtn() {
      let parentIds = []
      let menuIds = []
      for (let i = 0; i < this.menuList.length; i++) {
        if (this.menuList[i].checkedData.length != 0) {
          parentIds.push(this.menuList[i].menuId)
        }
        this.menuList[i].checkedData.forEach(item => {
          menuIds.push(item)
        })
      }
      if (menuIds.length == 0) return this.$message.warning('请选择权限授权')
      const [, err] = await to(
        getSureJurisdiction({
          clientCode: this.clientCode,
          roleId: this.roleId,
          menuIds: menuIds,
          parentMenuIds: parentIds,
        }),
      )
      if (err) return this.$message.warning(err.msg)
      this.$message.success('授权成功')
      this.jurisdictionDialogFormVisible = false
      this.$emit('success')
    },
  },
}
</script>

<style scoped lang="scss">
.Item_set ::v-deep .el-dialog__header {
  border-bottom: 1px solid #e2e2e2;
}
.Item_set ::v-deep .el-dialog__body {
  padding: 20px 36px;
}
.box-check {
  margin-top: 18px;
  .showmenu {
    display: flex;
    height: 60px;
  }
  .item_menu {
    font-size: 14px;
    font-family: SourceHanSansCN-Normal;
    color: #333333;
    line-height: 60px;
    padding-right: 28px;
    border-right: 1px solid #e2e2e2;
  }
  .iten-child-menu {
    font-size: 14px;
    font-family: SourceHanSansCN-Normal;
    color: #333333;
    line-height: 60px;
    padding-left: 28px;
    ::v-deep .el-checkbox {
      width: 110px;
    }
  }
}
.Item_set ::v-deep .el-dialog__footer {
  display: flex;
  justify-content: center;
}
</style>
